'use strict';

$(document).ready(function () {
    function validDescription() {
        var description = $('.cus-description').val();
        if (description === '') {
            $('.cus-description').addClass('is-invalid');
        } else {
            $('.cus-description').removeClass('is-invalid');
        }
    }
    $('.contactus-info-submit').click(function (e) {
        validDescription();
    });
    $('.cus-description').change(function () {
        validDescription();
    });

    $('form.contactus-info-form').submit(function (e) {
        e.preventDefault();
        var form = $(this);
        var button = $('.contactus-info-submit');
        var url = form.attr('action');
        $.spinner().start();
        button.attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                $.spinner().stop();
                if (data.success) {
                    form.trigger('reset');
                    $('.contactus-info-form').hide();
                    $('.contact-us-message').empty().append('<div class="pt-5 mt-5"><i class="fa fa-check-circle fa-5x text-success"></i></div><div class="pt-3 text-center"><h5 class="text-success">' + data.msgh + '</h5><h5 class="text-success">' + data.msg + '</h5></div>').show();
                } else if (data.error) {
                    $('#contactemail').addClass('is-invalid');
                    $('#contactemail').next('.invalid-feedback').empty().append(data.msg);
                } else {
                    $('.contact-us-message').empty().append('<div class="pb-3"><i class="fa fa-times-circle fa-3x text-danger"></i></div><div class="pt-1 text-center"><h5 class="text-danger">' + data.msg + '</h5></div>').show();
                }
                button.attr('disabled', false);
            },
            error: function (err) {
                $.spinner().stop();
                console.log('Error:', err);
                button.attr('disabled', false);
            }
        });
    });
    $('#contactusModal').on('hidden.bs.modal', function (e) {
        $('form.contactus-info-form').trigger('reset');
        $('.contact-us-message').empty();
        $('.contactus-info-form').show();
    });
});

