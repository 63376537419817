window.jQuery = window.$ = require('jquery');
require('slick-carousel');

var processInclude = require('base/util');
// 69358
// 69359
// 76175
// 165827
var lozad = require('lozad');
var observer = lozad('.lozad', {
    rootMargin: '10px 0px',
    threshold: 0.1,
    enableAutoReload: true,
    load: function (el) {
        el.src = el.getAttribute("data-src");
        el.onload = function () {
            if (el.complete) {
                el.classList.add('loaded');
                el.parentElement.classList.remove('skeleton-box');
            }
        };
    }
});
observer.observe();

$(document).ajaxComplete(function (event, request, settings, slick) {
    observer.observe();
});

// 26274 start
var cache = [];
function preLoadImages(imglist) {
    var argsLen = imglist.length;
    for (var i = argsLen; i--;) {
        var cacheImage = document.createElement('img');
        cacheImage.src = imglist[i];
        cache.push(cacheImage);
    }
}
var imgLength = $('body').find('img').length;
var imgSrcs = [];

if (imgLength !== 0) {
    $('body').find('img').each(function () {
        if ($(this).hasClass('lazyloaded') === true) {
            imgSrcs.push($(this).attr('src'));
        }
    });
    preLoadImages(imgSrcs);
}
// 26274 end
$(document).ready(function () {
    processInclude(require('base/components/menu'));	// 26357 Eyebrow bar - changed the path back to base cartridge
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/footer'));
    processInclude(require('plugin_wishlists/components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/contactUs')); // 318259
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/eyebrowBar'));    // 26357 Renamed menu.js to eyebrowBar.js
    processInclude(require('./components/mqlPopUp')); // 168198
    processInclude(require('./components/utmParams'));
    var GTM = require('gtm/gtm/init');
    GTM.init();

    var targetNav = $('.desktop-navigation .category-nav__sub');
    var catNav = $('.desktop-navigation .category-nav .navbar-nav .nav-item');
    var targetSubNav = catNav.eq(0).children('.navbar-nav').clone();
    var catNavLink = catNav.children('.nav-link');
    // subCatNav = catNav.children('.navbar-nav');
    catNav.eq(0).children('.nav-link').addClass('active');
    targetNav.html('');
    targetSubNav.appendTo(targetNav);
    // 146186
    targetNav.find('.navbar-nav').children('.nav-item:last-child').append('<a href="javascript:;" class="skip backcat" role="menuitem" aria-label="Back to Category" tabindex="0">Back to Category</a>');
    targetNav.prepend('<a href="javascript:;" class="skip-to-cat sr-only" role="menuitem" title="skip to back">skip to back</a>');
    // targetNav.find('.sr-only').removeClass('sr-only');

    if ($('.featured-dropdown-mobile').find('.content-asset').length === 0) {
        $('.featured-dropdown-mobile').remove();
    }
    if ($('.featured-dropdown__wrapper').find('.content-asset').length === 0) {
        $('.featured-dropdown').remove();
    }
    catNavLink.on('focus mouseenter', function () {
        targetNav.html('');
        catNavLink.removeClass('active');
        $(this).next('.navbar-nav').clone().appendTo(targetNav);
        $(this).addClass('active');
        targetNav.find('.navbar-nav').children('.nav-item:last-child').append('<a href="javascript:;" class="skip backcat" role="menuitem" aria-label="Back to Category" tabindex="0">Back to Category</a>');
        targetNav.prepend('<a href="javascript:;" class="skip-to-cat sr-only" role="menuitem" title="skip to back">skip to back</a>');
        // targetNav.find('.sr-only').removeClass('sr-only');
    });
    $(document).on('focus', '.subcat', function (e) {
        e.preventDefault();
        catNavLink.removeClass('active');
        $(this).parent('.nav-item').children('.nav-link').addClass('active');
    });
    $(document).on('focus', '.skip-to-cat', function (e) {
        e.preventDefault();
        $('a#' + $(this).next('.navbar-nav').attr('aria-label')).focus();
    });
    $(document).on('keyup click', '.subcat', function (e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            targetNav.find('.navbar-nav').children('.nav-item').eq(0).children('.nav-link')
                .focus();
        }
    });
    $(document).on('keyup click', '.backcat', function (e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            catNav.children('.nav-link.active').focus();
        }
    });
    $(document).on('keyup click', '.mainnav', function (e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            $(this).parents('.nav-item').next('.nav-item').children('.nav-link')
                .focus();
            $('.mega-menu').css({ height: '0' });
            $('.mega-menu, .backcat').attr('tabindex', '-1');
        }
    });
    $(document).on('focus', '.skip-to-main-nav', function (e) {
        e.preventDefault();
        $(this).parent('.nav-item').prev('.nav-item').children('.nav-link')
            .focus();
        $('.skip-to-main-nav').remove();
    });
    $(document).on('focus', '.mega-menu', function () {
        $('.mega-menu').css({ height: 'auto' });
        $('.mega-menu__wrapper').css('position', 'relative');
    });
    $(document).on('focus', '.next-link', function () {
        $('.skip-to-main-nav').remove();
        $(this).parents('.nav-item').prepend('<a href="javascript:;" class="skip-to-main-nav sr-only" title="skip to back">skip to main nav</a>');
    });
    $(document).on('blur', '.mega-menu', function () {
        $('.mega-menu').css({ height: '0' });
    });

    // 54929
    $('.jump-to-close').on('focus', function () {
        $('.mobile-menu__close').focus();
    });

    $('.mobile-arrow-down').off('click').on('click', function (e) {
        e.stopImmediatePropagation();
        var thisParent = $(this).parent();
        if (thisParent[0].nodeName === 'H5') {
            $(this).parent().next('.navbar-nav').slideToggle();
            $(this).toggleClass('active');
            if ($(this).attr('aria-expanded') === 'true') {
                $(this).attr('aria-expanded', 'false');
                $(this).parent().attr('aria-expanded', 'false');
            } else {
                $(this).attr('aria-expanded', 'true');
                $(this).parent().attr('aria-expanded', 'false');
            }
        } else {
            $(this).parent().children('.navbar-nav').slideToggle();
            $(this).toggleClass('active');
            if ($(this).attr('aria-expanded') === 'true') {
                $(this).attr('aria-expanded', 'false');
                $(this).prev('.nav-link').attr('aria-expanded', 'false');
            } else {
                $(this).attr('aria-expanded', 'true');
                $(this).prev('.nav-link').attr('aria-expanded', 'false');
            }
        }
    });

    $('.navbar-toggler').on('click', function () {
        $('body').addClass('overflow-hidden');
        $('.navbar-toggleable-sm').addClass('active');
        $('.modal-background').show();
        $('.mobile-menu__close').focus();
        $(this).attr('aria-expanded', 'true');
        if ($('.user-mobile .user-message').attr('data-login') === 'loggedIn') {
            $('.mobile-logout').show();
        } else {
            $('.mobile-logout').hide();
        }
    });
    $('.mobile-menu__close').on('click', function () {
        $('body').removeClass('overflow-hidden');
        $('.navbar-toggleable-sm').removeClass('active in');
        $('.modal-background').hide();
        $('.navbar-toggle').attr('aria-expanded', 'false');
        $(this).attr('aria-expanded', 'false');
    });
    // 54929

    $('.back-to-top a').on('click', function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
    });
    // 68814
    $('.featurecCourses li').each(function () {
        $(this).children('a').attr('title', $(this).children('a').text());
    });

    $('.has-mega-menu').on('click focus mouseenter', function () {
        $('.backcat').attr('tabindex', '0');
    });

    $('.go-to-enroll').on('click', function (e) {
        e.preventDefault();
        var thisTarget = $(this).attr('href');
        var targetOffset = $(thisTarget).offset().top;
        $("html, body").animate({ scrollTop: targetOffset - 170 }, "slow");
        $('#howToEnroll h3').focus();
    });
    $('.pdp-tab-list a').on('click', function (e) {
        e.preventDefault();
        var thisTarget = $(this).attr('href');
        var targetOffset = $(thisTarget).offset().top;
        $("html, body").animate({ scrollTop: targetOffset - 200 }, "slow");
        $(thisTarget).children().eq(0).focus();
    });
    if ($('body').find('.product-detail').length > 0) {
        $('body').addClass('page-pdp');
    }
    if ($('body').find('#howToEnroll').length === 0) {
        $('.go-to-enroll').remove();
    }

    $('.s-date label').keydown(function (e) {
        var scrollVal = true;
        if (e.which === 32 || e.which === 13) {
            $('.s-date input').prop('checked', false);
            $(this).prev('input').prop('checked', true);
            scrollVal = false;
        } else {
            scrollVal = true;
        }
        return scrollVal;
    });

    $(document).on('click', '.cart-box__add-tocart .add-to-cart', function () {
        var thisParentInputs = $(this).parents('.cart-box').find('.cart-box__start-date').find('input')
            .attr('name');
        if ($(this).parents('.cart-box').find('.cart-box__start-date').length > 0) {
            if ($('.cart-box__start-date input[name="' + thisParentInputs + '"]:checked').length === 0) {
                $(this).parents('.cart-box').find('.cart-box__start-date').find('.alert')
                    .show();
                $(this).parents('.cart-box').find('.cart-box__start-date').find('.alert')
                    .focus();
            }
        }
    });

    $('.s-date input').on('change', function () {
        $('.cart-box__start-date').find('.alert').hide();
    });

    $(document).on('click', '.atc-modal-header .close', function () {
        $('body').removeClass('modal-open').css('padding-left', '');
    });
});

$(window).scroll(function () {
    if ($(window).scrollTop() > 0) {
       // $('.header-top-banner').slideUp(); Story#60324
        $('.back-to-top').fadeIn();
    } else {
        // $('.header-top-banner').slideDown(); Story#60324
        $('.back-to-top').fadeOut();
    }
});
// 156512
$(window).on('load', function () {
    if (window.location.hash) {
        var hash = window.location.hash;
        if (hash === "#rfi") {
            var sghn = $('.st-global-header__nav').height();
            $('html, body').animate({
                scrollTop: $(hash).offset().top - sghn
            }, 150, 'swing');
        }
    }
});
// 37075
$(".btn-feedback-survey").click(function () {
    document.getElementById('feedbacksurvey').src += '';
});
// 37075
require('base/thirdParty/bootstrap');
require('base/components/spinner');
